import { NgIf, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import { SbDropdownModule } from '@app/+authenticated/shared/dropdown/sb-dropdown.module';
import { IconComponent } from '@app/+authenticated/shared/icon.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SearchInputComponent } from '@shared/form-inputs/search/search-input.component';
import { TreeviewModule } from '@shared/ngx-treeview/treeview.module';
import concat from 'lodash-es/concat';
import countBy from 'lodash-es/countBy';
import isArray from 'lodash-es/isArray';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import isNil from 'lodash-es/isNil';
import uniq from 'lodash-es/uniq';
import { Subscription } from 'rxjs';

import { TreeviewConfig } from '../ngx-treeview/treeview-config';
import { TreeviewHeaderTemplateContext } from '../ngx-treeview/treeview-header-template-context';
import { TreeviewI18n } from '../ngx-treeview/treeview-i18n';
import { TreeviewItem } from '../ngx-treeview/treeview-item';
import { TreeviewItemTemplateContext } from '../ngx-treeview/treeview-item-template-context';
import { SelectAction } from './interfaces';
import { MultiSelectI18nService } from './multiSelect.i18n.service';

const noop = () => {};

export const MULTI_SELECT_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiselectComponent),
  multi: true,
};

const updateTreeLeaf = (item: TreeviewItem, selected: string[]) => {
  const shouldBeChecked = selected.indexOf(item.value) !== -1;
  // do not mutate if there are no changes
  if (item.checked === shouldBeChecked) {
    return item;
  }

  const modifiedItem = {
    ...item,
    checked: shouldBeChecked,
    disabled: item.disabled,
  };

  return new TreeviewItem(modifiedItem);
};

const updateTreeParent = (item: TreeviewItem, selected: string[]) => {
  const children = item.children.map((child) => mapItem(child, selected));
  // do not mutate parent if there are no changes
  if (isEqual(children, item.children)) {
    return new TreeviewItem(item, true);
  }

  const modifiedParent = {
    ...item,
    children,
  };

  return new TreeviewItem(modifiedParent, true);
};

/**
 * Recursively map items and set checked property without mutating the original items
 * @param {TreeviewItem} item
 * @param {string[]} selected
 * @returns {TreeviewItem}
 */
const mapItem = (item: TreeviewItem, selected: string[]) => {
  if (item.children && item.children.length > 0) {
    return updateTreeParent(item, selected);
  }

  //update TreeLeave item
  return updateTreeLeaf(item, selected);
};

@Component({
  selector: 'dl-multiselect',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './multiselect.component.html',
  providers: [MULTI_SELECT_INPUT_CONTROL_VALUE_ACCESSOR, { provide: TreeviewI18n, useClass: MultiSelectI18nService }],
  standalone: true,
  imports: [
    NgIf,
    SearchInputComponent,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    TreeviewModule,
    SbDropdownModule,
    IconComponent,
    NgStyle,
  ],
})
export class MultiselectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input()
  public inlineTextClasses = '';

  @Input()
  public config: TreeviewConfig = TreeviewConfig.create({
    hasScheduledOnlyCheckBox: false,
    hasAvailableOnlyCheckBox: false,
    hasAllCheckBox: true,
    hasFilter: true,
    hasConflictFilterCheckBox: false,
    hasCollapseExpand: false,
    maxHeight: 250,
  });

  @Input()
  public headerTemplate: TemplateRef<TreeviewHeaderTemplateContext>;
  @Input()
  public itemTemplate: TemplateRef<TreeviewItemTemplateContext>;

  @Input()
  public header: string;

  @Input()
  public dark = false;

  @Output()
  public conflictFilter: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public filteredEmployees: Set<string>;

  @Input()
  public availableEmployees: Set<string>;

  @Input()
  public placeholder: string;

  private _items: TreeviewItem[];
  private selected: string[] = [];
  private selectedCount: { [value: string]: number } = {};

  public disabled = false;
  public filterText = '';

  private filterSubscription: Subscription;
  public employeeFilterCheckbox = new UntypedFormControl(false);
  // internal functions to call by ControlValueAccessor
  private onTouched: () => void = noop;
  private onModelChange: (_: any) => void = noop;

  public constructor(
    private cd: ChangeDetectorRef,
    private readonly translate: TranslateService,
  ) {}

  public ngOnInit() {
    this.filterSubscription = this.employeeFilterCheckbox.valueChanges.subscribe((value) => {
      this.conflictFilter.emit(value);
    });
  }

  private getButtonText() {
    let checkedItems: TreeviewItem[] = [];
    let uncheckedItems: TreeviewItem[] = [];

    if (!isNil(this.items)) {
      for (const item of this.items) {
        const itemSelection = item.getSelection();
        checkedItems = concat(checkedItems, itemSelection.checkedItems);
        uncheckedItems = concat(uncheckedItems, itemSelection.uncheckedItems);
      }
    }

    const selection = {
      checkedItems: checkedItems,
      uncheckedItems: uncheckedItems,
    };

    switch (selection.checkedItems.length) {
      case 0:
        return this.placeholder;
      case 1:
        return selection.checkedItems[0].text;
      default:
        return `${selection.checkedItems.length} ${this.translate.instant('selected')}`;
    }
  }

  public ngOnDestroy() {
    this.filterSubscription.unsubscribe();
  }

  @Input()
  public set items(items: TreeviewItem[]) {
    //modify items so selected values are checked ( and all others will be unchecked )
    this.updateItems(items, this.selected);
  }

  public get items() {
    return this._items;
  }

  public selectedChange(selectedItems) {
    const action = this.determineAction(selectedItems);

    //sort values so isEqual comparison works
    let selected = [...selectedItems].sort();

    if (action.type === 'deselect') {
      selected = selected.filter((s) => s !== action.item);
    }

    selected = uniq(selected);

    if (!isEqual(selected, this.selected)) {
      this.updateItems(this._items, selected);

      //trigger change on form
      this.onModelChange(selected);
    }
  }

  /**
   * set the checked property on all Treeview items
   * @param {TreeviewItem[]} items
   * @param {string[]} selected
   */
  private updateItems(items: TreeviewItem[], selected: string[]) {
    if (!items) {
      return;
    }
    // recursively walk through all treeview items and set the checked value
    this._items = items.map((item) => mapItem(item, selected));
    this.selected = uniq(selected);
    this.cd.markForCheck();
  }

  /********************************
   * ControlValue methods
   ********************************/

  /**
   * This is called from a form input to set the internal value
   * @param {string[]} selected
   */
  public writeValue(selected: string[]): void {
    if (!isArray(selected)) {
      if (selected === '') {
        return;
      }

      selected = [selected];
    }

    //sort values so isEqual comparison works
    selected = [...selected].sort();

    if (isEqual(this.selected, selected)) {
      return;
    }

    this.updateItems(this.items, selected);

    this.cd.markForCheck();
  }

  public registerOnChange(fn: (_: any) => void): void {
    this.onModelChange = fn;
  }

  public registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // Determine what happened when the user interacted with an item.
  public determineAction(selectedItems: string[]): SelectAction {
    /*
      The problem is that selectedItems contains all
      items which are currently selected. This means
      that if an item is rendered 4 times and is
      selected 3 times the array will be:

      ['42', '42', '42'].

      To determine the action which occurred we must
      know if there are now more or less 42's than
      before.
    */

    // First we count all occurrences of the items.
    const newSelectedCount = countBy(selectedItems);

    // If we have no previous count it is always a `select` or `nothing`.
    if (isEmpty(this.selectedCount)) {
      this.selectedCount = newSelectedCount;
      const item = selectedItems[0];

      /*
        If there is no item then nothing happened, happens
        because `selectedChange` is called by `ngx-treeview`
        without any user interaction when the component is
        initialising.
      */
      const type = item ? 'select' : 'nothing';

      return { item, type };
    }

    // At this point we know an item has changed, now we must find out which.
    for (const item in newSelectedCount) {
      const count = newSelectedCount[item];
      const originalCount = this.selectedCount[item] || 0;

      // If the counts differ we have found the item responsible for the `selectedChange` event.
      if (count !== originalCount) {
        this.selectedCount = newSelectedCount;

        const type = count > originalCount ? 'select' : 'deselect';

        return { item, type };
      }
    }

    /*
      `newSelectedCount` can be empty when nothing is selected in
      that case report that nothing happened.

      Also ngx-treeview sometimes calls `selectedChange` twice
      in a row with no changes and user interaction, also report
      that nothing happened in that case.
    */
    this.selectedCount = newSelectedCount;
    return { type: 'nothing', item: '' };
  }

  public dropdownClosed() {
    this.onTouched();
  }
}
