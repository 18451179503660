<ng-container *ngIf="textOnly">
  <div
    class="cursor-pointer"
    cdkOverlayOrigin
    (click)="toggleOverlay()"
    #trigger="cdkOverlayOrigin"
    style="font-weight: 600;"
  >
    {{ buttonText }}&nbsp;<icon svgIcon="chevron-down-solid"></icon>
  </div>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayPositions]="positions"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
    (backdropClick)="toggleOverlay()"
  >
    <div class="content-container">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ng-template>
</ng-container>
<ng-container *ngIf="!textOnly">
  <button
    (click)="buttonClicked.emit()"
    [ladda]="isLoading"
    type="button"
    class="btn btn-primary btn-large"
    [ngClass]="{ 'border-right-none': !dropdownDisabled }"
  >
    <icon class="dropdown-icon" *ngIf="icon !== undefined" [svgIcon]="icon"></icon>
    {{ buttonText }}
  </button>
</ng-container>
<ng-container *ngIf="!dropdownDisabled && !textOnly">
  <button
    cdkOverlayOrigin
    (click)="toggleOverlay()"
    #trigger="cdkOverlayOrigin"
    type="button"
    class="btn btn-primary btn-large dropdown-toggle dropdown-toggle-split border-left-none"
  >
    <span class="sr-only">Toggle Dropdown</span>
  </button>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayPositions]="positions"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
    (backdropClick)="toggleOverlay()"
  >
    <div class="content-container">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ng-template>
</ng-container>
