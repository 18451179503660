import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, TemplateRef } from '@angular/core';
import { ConnectionPositionPair } from '@angular/cdk/overlay';

@Component({
  selector: 'sb-dropdown-button',
  templateUrl: 'sb-dropdown-button.component.html',
  styleUrls: ['sb-dropdown-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SbDropdownButtonComponent implements OnChanges {
  @Input()
  public dropdownDisabled: boolean;
  @Input()
  public textOnly: boolean;
  @Input()
  public isLoading: boolean;
  @Input()
  public icon: string;
  @Input()
  public buttonText: string;
  @Input()
  public contentTemplate: TemplateRef<any>;

  @Output()
  public buttonClicked = new EventEmitter();

  public isOpen: boolean;

  @Input()
  public positions = [
    new ConnectionPositionPair({ originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }, 0, 2),
  ];

  public toggleOverlay() {
    if (this.dropdownDisabled) {
      return;
    }
    this.isOpen = !this.isOpen;
  }

  public close() {
    this.isOpen = false;
  }

  public ngOnChanges() {
    if (this.dropdownDisabled) {
      this.isOpen = false;
    }
  }
}
