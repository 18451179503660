import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { SharedLaddaModule } from '../../../shared/shared-ladda.module';
import { IconComponent } from '../icon.component';
import { SbDropdownButtonComponent } from './button/sb-dropdown-button.component';
import { SbDropdownIconComponent } from './icon/sb-dropdown-icon.component';

const components = [SbDropdownButtonComponent, SbDropdownIconComponent];
@NgModule({
  imports: [OverlayModule, CommonModule, IconComponent, SharedLaddaModule],
  declarations: components,
  exports: components,
})
export class SbDropdownModule {
  public static forRoot(): ModuleWithProviders<SbDropdownModule> {
    return {
      ngModule: SbDropdownModule,
    };
  }
}
