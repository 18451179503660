<ng-template
  #defaultHeaderTemplate
  let-item="item"
  let-allScheduled="allScheduled"
  let-allAvailable="allAvailable"
  let-config="config"
  let-onCollapseExpand="onCollapseExpand"
  let-onCheckedChange="onCheckedChange"
  let-onScheduledChange="onScheduledChange"
  let-onAvailableChange="onAvailableChange"
  let-onFilterTextChange="onFilterTextChange"
>
  <div *ngIf="config.hasFilter" class="w-14rem">
    <search-input
      [(ngModel)]="filterText"
      (ngModelChange)="onFilterTextChange($event)"
      [ngModelOptions]="{ standalone: true }"
    ></search-input>
  </div>
  <div>
    <ng-container *ngIf="!dark">
      <div
        *ngIf="
          config.hasAllCheckBox ||
          config.hasCollapseExpand ||
          config.hasConflictFilterCheckBox ||
          config.hasScheduledOnlyCheckBox ||
          config.hasAvailableOnlyCheckBox
        "
      >
        <label *ngIf="config.hasAllCheckBox" class="checkbox">
          <input
            type="checkbox"
            class="checkbox-input"
            [(ngModel)]="item.checked"
            (ngModelChange)="onCheckedChange($event)"
            [ngModelOptions]="{ standalone: true }"
            [indeterminate]="item.indeterminate"
          />
          <span class="checkbox-indicator"></span>
          <span class="checkbox-description">{{ 'Select all' | translate }}</span>
        </label>
        &nbsp;
        <label *ngIf="config.hasConflictFilterCheckBox" class="checkbox">
          <input type="checkbox" class="checkbox-input" [formControl]="employeeFilterCheckbox" />
          <span class="checkbox-indicator"></span>
          <span class="checkbox-description">{{ 'Show compatible employees' | translate }}</span>
        </label>
        &nbsp;
        <label *ngIf="config.hasScheduledOnlyCheckBox" class="checkbox">
          <input
            type="checkbox"
            class="checkbox-input"
            [(ngModel)]="allScheduled.checked"
            (ngModelChange)="onScheduledChange($event, filteredEmployees)"
            [ngModelOptions]="{ standalone: true }"
            [indeterminate]="allScheduled.indeterminate"
          />
          <span class="checkbox-indicator"></span>
          <span class="checkbox-description">{{ 'Select Scheduled' | translate }}</span>
        </label>

        <label *ngIf="config.hasAvailableOnlyCheckBox" class="checkbox">
          <input
            type="checkbox"
            class="checkbox-input"
            [(ngModel)]="allAvailable.checked"
            (ngModelChange)="onAvailableChange($event, availableEmployees)"
            [indeterminate]="allAvailable.indeterminate"
          />
          <span class="checkbox-indicator"></span>
          <span class="checkbox-description">{{ 'Select available' | translate }}</span>
        </label>

        <label *ngIf="config.hasCollapseExpand" class="pull-right form-check-label" (click)="onCollapseExpand()">
          <i aria-hidden="true" class="fa" [class.fa-expand]="item.collapsed" [class.fa-compress]="!item.collapsed"></i>
        </label>
      </div>
      <div *ngIf="config.hasDivider" class="dropdown-divider"></div>
    </ng-container>

    <ng-container *ngIf="dark">
      <div
        *ngIf="
          config.hasAllCheckBox ||
          config.hasCollapseExpand ||
          config.hasScheduledOnlyCheckBox ||
          config.hasAvailableOnlyCheckBox
        "
        class="font-medium bg-grey-100 flex items-center pl-[12px] h-[40px] text-word-mark-800"
      >
        <label *ngIf="config.hasAllCheckBox" class="checkbox">
          <input
            type="checkbox"
            class="checkbox-input"
            [(ngModel)]="item.checked"
            (ngModelChange)="onCheckedChange($event)"
            [indeterminate]="item.indeterminate"
          />
          <span class="checkbox-indicator"></span>
          <span class="checkbox-description">&nbsp;</span>
        </label>
        <label *ngIf="config.hasScheduledOnlyCheckBox" class="checkbox">
          <input
            type="checkbox"
            class="checkbox-input"
            [(ngModel)]="allScheduled.checked"
            (ngModelChange)="onScheduledChange($event, filteredEmployees)"
            [indeterminate]="allScheduled.indeterminate"
          />
          <span class="checkbox-indicator"></span>
          <span class="checkbox-description"></span>
        </label>
        <label *ngIf="config.hasAvailableOnlyCheckBox" class="checkbox">
          <input
            type="checkbox"
            class="checkbox-input"
            [(ngModel)]="allAvailable.checked"
            (ngModelChange)="onAvailableChange($event, availableEmployees)"
            [indeterminate]="allAvailable.indeterminate"
          />
          <span class="checkbox-indicator"></span>
          <span class="checkbox-description"></span>
        </label>
        <div class="location-name">
          <a class="name-label">
            {{ header }}
          </a>
        </div>
        <label *ngIf="config.hasCollapseExpand" class="pull-right form-check-label" (click)="onCollapseExpand()">
          <i aria-hidden="true" class="fa" [class.fa-expand]="item.collapsed" [class.fa-compress]="!item.collapsed"></i>
        </label>
      </div>
      <div *ngIf="config.hasDivider" class="dropdown-divider"></div>
    </ng-container>
  </div>
</ng-template>

<ng-template
  #itemInlineTemplate
  let-item="item"
  let-onCollapseExpand="onCollapseExpand"
  let-onCheckedChange="onCheckedChange"
>
  <div class="form-check">
    <label class="checkbox">
      <input
        type="checkbox"
        class="checkbox-input"
        [(ngModel)]="item.checked"
        (ngModelChange)="onCheckedChange()"
        [disabled]="item.disabled"
        [indeterminate]="item.indeterminate"
      />
      <span class="checkbox-indicator"></span>
      <span class="checkbox-description {{ inlineTextClasses }}">{{ item.text }}</span>
    </label>
  </div>
</ng-template>

<ngx-treeview
  [items]="items"
  [config]="config"
  (selectedChange)="selectedChange($event)"
  [itemTemplate]="itemTemplate ? itemTemplate : itemInlineTemplate"
  [headerTemplate]="headerTemplate ? headerTemplate : defaultHeaderTemplate"
  [class.dark]="dark"
>
</ngx-treeview>
