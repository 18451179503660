import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TreeviewI18n } from '../ngx-treeview/treeview-i18n';
import { TreeviewSelection } from '../ngx-treeview/treeview-item';

@Injectable()
export class MultiSelectI18nService implements TreeviewI18n {
  public constructor(protected translateService: TranslateService) {}

  public getText(selection: TreeviewSelection): string {
    if (!selection) {
      return '';
    }

    switch (selection.checkedItems.length) {
      case 0:
        return '';
      case 1:
        return selection.checkedItems[0].text;
      default:
        return `${selection.checkedItems.length} ${this.translateService.instant('selected')}`;
    }
  }

  public getAllCheckboxText(): string {
    return this.translateService.instant('All');
  }

  public getFilterPlaceholder(): string {
    return this.translateService.instant('Search');
  }

  public getFilterNoItemsFoundText(): string {
    return this.translateService.instant('No items found');
  }

  public getTooltipCollapseExpandText(isCollapse: boolean): string {
    return isCollapse ? this.translateService.instant('Expand') : this.translateService.instant('Collapse');
  }
}
