import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InputTextModule } from 'primeng/inputtext';

export const SEARCH_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SearchInputComponent),
  multi: true,
};

@Component({
  selector: 'search-input',
  template: `
    <span class="p-input-icon-left h-8.5 w-full">
      <i class="pi pi-search text-grey-400"></i>
      <input
        class="h-full max-h-full w-full border border-grey-200 focus:shadow-none"
        type="text"
        pInputText
        inputFocus
        [ngModel]="value"
        [disabled]="disabled"
        (ngModelChange)="onChangeInput($event)"
        [ngModelOptions]="{ standalone: true }"
        [placeholder]="placeholder ? placeholder : ('Search' | translate)"
      />
    </span>
  `,
  providers: [SEARCH_INPUT_CONTROL_VALUE_ACCESSOR],
  standalone: true,
  imports: [FormsModule, TranslateModule, InputTextModule],
})
export class SearchInputComponent implements ControlValueAccessor, OnDestroy {
  @Input()
  public placeholder;

  public value: string;
  public disabled: boolean;

  // internal functions to call when ControlValueAccessor
  // gets called
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouched: any = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onModelChange: any = () => {};

  public onChangeInput(value: string) {
    if (this.value === value) {
      return;
    }

    this.value = value;

    this.onChange();
  }

  private onChange() {
    this.onModelChange(this.value);
  }

  public updateValue(value: string) {
    this.writeValue(value);
    this.onChange();
  }

  // called by the reactive form control
  public registerOnChange(fn: () => void): void {
    // assigns to our internal model change method
    this.onModelChange = fn;
  }

  public registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  // writes the value to the local component
  // that binds to the "value"
  public writeValue(value: string) {
    if (value === null) {
      return;
    }

    this.value = value;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public ngOnDestroy() {
    this.onChangeInput('');
  }
}
