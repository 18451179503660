import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IconComponent } from '../../+authenticated/shared/icon.component';
import { TreeviewConfig } from './treeview-config';
import { DefaultTreeviewEventParser, TreeviewEventParser } from './treeview-event-parser';
import { TreeviewI18n, TreeviewI18nDefault } from './treeview-i18n';
import { TreeviewItemComponent } from './treeview-item.component';
import { TreeviewComponent } from './treeview.component';
import { TreeviewPipe } from './treeview.pipe';

@NgModule({
  imports: [FormsModule, CommonModule, IconComponent],
  declarations: [TreeviewComponent, TreeviewItemComponent, TreeviewPipe],
  exports: [TreeviewComponent, TreeviewPipe],
})
export class TreeviewModule {
  public static forRoot(): ModuleWithProviders<TreeviewModule> {
    return {
      ngModule: TreeviewModule,
      providers: [
        TreeviewConfig,
        { provide: TreeviewI18n, useClass: TreeviewI18nDefault },
        { provide: TreeviewEventParser, useClass: DefaultTreeviewEventParser },
      ],
    };
  }
}
