<ng-container *ngIf="!dropdownDisabled">
  <icon
    cdkOverlayOrigin
    (click)="toggleOverlay($event)"
    #trigger="cdkOverlayOrigin"
    class="dropdown-icon cursor-pointer"
    [svgIcon]="icon"
  ></icon>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayPositions]="positions"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
    (backdropClick)="toggleOverlay($event)"
  >
    <div class="content-container">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ng-template>
</ng-container>
