import { NgIf, NgStyle } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_SHIFTBASE_COLORS, lightenHex } from '@shiftbase-com/utilities';
import { clsx } from 'clsx';

import { IconComponent } from '../../../+authenticated/shared/icon.component';
import { EmployeeModel } from '../../../reducers/orm/employee/employee.model';

@Component({
  selector: 'sb-avatar',
  standalone: true,
  imports: [NgStyle, NgIf, IconComponent],
  template: `
    @if (hasAvatar) {
      <img class="h-full w-full object-cover" src="{{ imageUrl }}" (error)="fetchAvatarFailure()" />
    } @else {
      <div
        class="flex h-full w-full select-none items-center justify-center font-semibold"
        [style.color]="this.initialsColor"
        [style.background-color]="bgColor"
        [style.font-size]="size * 0.5 + 'px'"
        [style.line-height]="size + 'px'"
      >
        {{ initials }}
      </div>
    }
    @if (canEdit) {
      <div class="edit-avatar-overlay rounded-full border-none" (click)="editAvatar()">
        <icon svgIcon="camera-solid" class="h-100 w-100"></icon>
      </div>
    }
  `,
})
export class SBAvatarComponent {
  @Input()
  public employee: EmployeeModel;
  @Input()
  public size: number;
  @Input()
  public canEdit: boolean;

  @HostBinding('class')
  public get classes() {
    return clsx('relative flex items-center justify-center overflow-hidden rounded-full');
  }

  @HostBinding('style')
  public get style() {
    return {
      width: this.size + 'px',
      height: this.size + 'px',
    };
  }

  public failedAvatarFetch = false;

  public constructor(private router: Router) {}

  public get hasAvatar() {
    if (this.failedAvatarFetch) {
      return false;
    }
    return this.employee?.avatar_file_name;
  }

  public get imageUrl(): string {
    if (this.size <= 15) return this.employee.avatar_15x15;
    if (this.size <= 24) return this.employee.avatar_24x24;
    if (this.size <= 30) return this.employee.avatar_30x30;
    if (this.size <= 45) return this.employee.avatar_45x45;
    if (this.size <= 60) return this.employee.avatar_60x60;
    return this.employee.avatar_150x200;
  }

  public get initials(): string {
    if (!this.employee) {
      return '';
    }
    const firstName = this.employee.first_name;
    const lastName = this.employee.last_name;
    const firstLetter = firstName ? firstName.charAt(0).toUpperCase() : '';
    const secondLetter = lastName ? lastName.charAt(0).toUpperCase() : '';
    return `${firstLetter}${secondLetter}`;
  }

  public get initialsColor(): string {
    const id = parseInt(this.employee?.id ?? '', 10);
    const colorIndex = id % DEFAULT_SHIFTBASE_COLORS.length;
    return DEFAULT_SHIFTBASE_COLORS[colorIndex];
  }

  public get bgColor(): string {
    return lightenHex(this.initialsColor);
  }

  public fetchAvatarFailure() {
    this.failedAvatarFetch = true;
  }

  public editAvatar() {
    this.router.navigate(['', { outlets: { modal: ['employee-avatar', this.employee.id] } }]);
  }
}
