import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, TemplateRef } from '@angular/core';
import { ConnectionPositionPair } from '@angular/cdk/overlay';

@Component({
  selector: 'sb-dropdown-icon',
  templateUrl: 'sb-dropdown-icon.component.html',
  styleUrls: ['sb-dropdown-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SbDropdownIconComponent implements OnChanges {
  @Input()
  dropdownDisabled: boolean;
  @Input()
  public icon: string;
  @Input()
  contentTemplate: TemplateRef<any>;

  @Output()
  buttonClicked = new EventEmitter();

  public isOpen: boolean;

  @Input()
  public positions = [
    new ConnectionPositionPair({ originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }, 0, 2),
  ];

  public toggleOverlay(event: Event) {
    event.stopPropagation();
    if (this.dropdownDisabled) {
      return;
    }
    this.isOpen = !this.isOpen;
  }

  public close() {
    this.isOpen = false;
  }

  public ngOnChanges() {
    if (this.dropdownDisabled) {
      this.isOpen = false;
    }
  }
}
